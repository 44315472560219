<script>
	export default {
		onLaunch: function() {
			// console.log('App Launch')
			let globalData = getApp().globalData
			globalData.baseUrl = ''
			globalData.userInfo = null
			globalData.token = ''
			globalData.uid = ''

			//设备编号
			globalData.device_num = ''
			
			//系统信息
			globalData.sysInfo = null

			//appid
			globalData.appid = ''

		},
		onShow: function() {
			// console.log('App Show')
		},
		onHide: function() {
			// console.log('App Hide')
		},
		onError(err) {
			console.log(err)
		}
	}
</script>

<style lang="scss">
	@import '@climblee/uv-ui/index.scss';

	/*Color UI*/
	@import 'colorui/main.css';
	@import 'colorui/icon.css';

	/*每个页面公共css */
	@import '_ui.scss';
	@import 'app.scss';
</style>